import { Avatar, styled } from '@mui/material';
import { StyledAvatarProps } from './CustomAvatar.types';

export const StyledAvatar = styled(Avatar)<StyledAvatarProps>`
  font-weight: bold;
  cursor: ${({ onClick, isPointer }) =>
    onClick || isPointer ? 'pointer' : 'default'};

  color: ${({ theme, customStyles: { textColor } }) =>
    textColor || theme.palette.grey[50]};

  background-color: ${({ customStyles: { backgroundColor } }) =>
    backgroundColor};

  width: ${({ customStyles: { width }, theme }) => theme.spacing(width.mobile)};

  height: ${({ customStyles: { height }, theme }) =>
    theme.spacing(height.mobile)};

  font-size: ${({ customStyles: { fontSize }, theme }) =>
    theme.spacing(fontSize?.mobile)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: ${({ customStyles: { width }, theme }) =>
      theme.spacing(width.desktop)};

    height: ${({ customStyles: { height }, theme }) =>
      theme.spacing(height.desktop)};

    font-size: ${({ customStyles: { fontSize }, theme }) =>
      theme.spacing(fontSize?.desktop)};
  }

  ${({ customStyles: { bordered, borderColor }, theme }) =>
    bordered || borderColor
      ? `border: 1px solid ${borderColor || theme.palette.divider};`
      : ''}
`;
