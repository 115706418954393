import { color } from 'theme.material';
import { StyledAvatar } from './CustomAvatar.styles';
import { AvatarProps, StyledAvatarProps } from './CustomAvatar.types';

export default function CustomAvatar({
  size,
  name,
  image,
  onClick,
  fontSize,
  textColor,
  backgroundColor,
  className,
  isPointer,
  bordered,
  sx,
  variant,
  borderColor,
}: AvatarProps): JSX.Element {
  const getCustomStyles = (): StyledAvatarProps['customStyles'] => ({
    textColor,
    backgroundColor,
    height: { mobile: size.mobile, desktop: size.desktop },
    width: { mobile: size.mobile, desktop: size.desktop },
    fontSize: { mobile: fontSize.mobile, desktop: fontSize.desktop },
    bordered,
    borderColor,
  });

  if (image) {
    return (
      <StyledAvatar
        alt={name}
        className={className}
        src={image}
        onClick={onClick}
        isPointer={isPointer}
        customStyles={getCustomStyles()}
        sx={sx}
        variant={variant}
      />
    );
  }

  if (name) {
    return (
      <StyledAvatar
        onClick={onClick}
        isPointer={isPointer}
        className={className}
        customStyles={getCustomStyles()}
        sx={sx}
        variant={variant}
      >
        {name.charAt(0).toUpperCase()}
      </StyledAvatar>
    );
  }

  return (
    <StyledAvatar
      alt="Anonymous"
      className={className}
      src="/icons/user.svg"
      onClick={onClick}
      isPointer={isPointer}
      customStyles={getCustomStyles()}
      sx={sx}
      variant={variant}
    />
  );
}

CustomAvatar.defaultProps = {
  image: '',
  name: '',
  size: {
    mobile: 8,
    desktop: 11,
  },
  textColor: color.grey[50],
  backgroundColor: color.teal[500],
  fontSize: { desktop: 4, mobile: 4 },
};
