import { useState, useEffect } from 'react';

function useHasMounted(): boolean {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
    return () => setHasMounted(false);
  }, []);

  return hasMounted;
}

export default useHasMounted;
